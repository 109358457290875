import type { Product } from "../types";

export const getProduct = async (sku: string): Promise<Product> => {
  const response = await fetch(
    `${import.meta.env.VITE_ROMA_API}/products/${sku}`
  );

  if (!response.ok) {
    // TODO
    throw new Error();
  }

  const data: Product = await response.json();
  return data;
};
