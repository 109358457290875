import { cache } from "@solidjs/router";
import { getProduct } from "../roma-api/products/";



export const useProduct = cache(async(sku: string)=>{
  return  getProduct(sku);
}, "products")


